<!-- 28-4-3 -->
<template>
  <div class="flex gap-24">
    <div class="flex-1">
      <st-box>
        <st-form-title
          :formTitle="$t('studio.prj_prod_mngmt.rating_build_review.direct_input.sub_title')"
          required
        />
        <div class="pt-4 flex gap-16 items-start">
          <div class="flex-1">
            <input-text
              name="searchText"
              :placeholder="
                $t('studio.prj_prod_mngmt.rating_build_review.direct_input.place_holder')
              "
              :rules="{
                required: {
                  value: true,
                  message: $t('studio.prj_prod_mngmt.rating_build_review.direct_input.required_msg')
                },
                regex: {
                  regex: ALPHABE_NUMBER_HYPHEN_REGEX,
                  message: $t(
                    'studio.prj_prod_mngmt.rating_build_review.direct_input.allowed_char_msg'
                  )
                },
                max_length: {
                  value: 30
                }
              }"
              :clearable="false"
              :countable="false"
              variant="outline"
              size="lg"
              maxLength="30"
            />
          </div>
          <s-button
            variant="secondary"
            size="sm"
            class="!min-w-84 shrink-0 mt-[6px]"
            :disabled="!values.searchText"
            @click="onSearch"
          >
            {{ $t('studio.prj_prod_mngmt.rating_build_review.direct_input.add_btn') }}
          </s-button>
        </div>
        <div class="flex flex-col gap-12 mt-12">
          <div
            v-for="rating in ratingList"
            :key="rating.ratingId"
            class="p-20 rounded-2xl bg-neutral-variant-2-pressed gap-16 flex items-start"
          >
            <div class="flex gap-16 flex-1">
              <div class="flex-1 flex flex-col gap-4">
                <dl class="flex items-start gap-8">
                  <dt
                    class="shrink-0 pt-[.1rem] w-[11.2rem] text-sm leading-md text-on-surface-elevation-4 font-medium"
                  >
                    {{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.direct_input.rating_info_game_title'
                      )
                    }}
                  </dt>
                  <dd
                    class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2 break-all"
                  >
                    {{ rating.productName }}
                  </dd>
                </dl>
                <dl class="flex items-start gap-8">
                  <dt
                    class="shrink-0 pt-[.1rem] w-[11.2rem] text-sm leading-md text-on-surface-elevation-4 font-medium"
                  >
                    {{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.direct_input.rating_info_rating'
                      )
                    }}
                  </dt>
                  <dd
                    class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2 break-all"
                  >
                    {{
                      $t(getAgeRatingText(rating.ageRating as RatingAge), {
                        rating: rating.ageRating
                      })
                    }}
                  </dd>
                </dl>
                <dl class="flex items-start gap-8">
                  <dt
                    class="shrink-0 pt-[.1rem] w-[11.2rem] text-sm leading-md text-on-surface-elevation-4 font-medium"
                  >
                    {{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.direct_input.rating_info_category'
                      )
                    }}
                  </dt>
                  <dd
                    class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2 break-all"
                  >
                    {{
                      translateList(convertRatingContentInfosToTranslationText(rating.descriptor))
                    }}
                  </dd>
                </dl>
                <dl class="flex items-start gap-8">
                  <dt
                    class="shrink-0 pt-[.1rem] w-[11.2rem] text-sm leading-md text-on-surface-elevation-4 font-medium"
                  >
                    {{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.direct_input.rating_info_reg_num'
                      )
                    }}
                  </dt>
                  <dd
                    class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2 break-all"
                  >
                    {{ rating.ratingId }}
                  </dd>
                </dl>
                <dl class="flex items-start gap-8">
                  <dt
                    class="shrink-0 pt-[.1rem] w-[11.2rem] text-sm leading-md text-on-surface-elevation-4 font-medium"
                  >
                    {{
                      $t(
                        'studio.prj_prod_mngmt.rating_build_review.direct_input.rating_info_assigned_date'
                      )
                    }}
                  </dt>
                  <dd
                    class="flex-1 text-md font-medium leading-lg text-on-surface-elevation-2 break-all"
                  >
                    {{ getDateTimeByLocale(rating.issuedAt) }}
                  </dd>
                </dl>
              </div>
            </div>
            <button type="button" class="shrink-0 w-20 h-20 text-on-surface-elevation-2">
              <s-icon
                icon="ic-v2-control-close-line"
                size="3xl"
                class="flex"
                @click="onRatingRemove(rating.ratingId)"
              />
            </button>
          </div>
        </div>
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="onSubmit">
          {{
            isReleased
              ? $t('studio.common.live_apply_btn')
              : $t('studio.prj_prod_mngmt.rating_build_review.direct_input.reg_btn')
          }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="
          $t('studio.prj_prod_mngmt.rating_build_review.self_review.rating_reg.guide3')
        "
      >
        <safe-html :html="$t('studio.prj_prod_mngmt.rating_build_review.direct_input.guide_1')" />
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { storeToRefs } from 'pinia';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  fetchRatingsProductsApi,
  registerProductDirectRatingApi,
  searchRatingWithRatingClassificationApi
} from '@/apis/rating.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm } from '@/composables/useDialog';
import { ALPHABE_NUMBER_HYPHEN_REGEX } from '@/constants/regex.const';
import { PRODUCT_RATING_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { RatingAge, RatingPageMode } from '@/enums/rating.enum';
import useProductStore from '@/stores/product.store';
import type { RegisterProductDirectRatingRequest } from '@/types/rating/rating.request.type';
import type { RatingSearchResponse, ReleasedRatings } from '@/types/rating/rating.response.type';
import { redirectTo } from '@/utils/common.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import {
  convertRatingContentInfosToTranslationText,
  getAgeRatingText,
  getCorrectRating
} from '@/utils/rating.util';

const { checkProductPermission } = useApp();

const { t } = useI18n();
const route = useRoute();

const props = defineProps<{
  mode: RatingPageMode;
}>();

const productStore = useProductStore();
const { isReleased } = storeToRefs(productStore);

const productNo = route.params.productId as string;

const originalRatingList = ref<RatingSearchResponse[]>([]);
const ratingList = ref<RatingSearchResponse[]>([]);

const { handleSubmit, values } = useForm<{ searchText: string }>({
  initialValues: {
    searchText: ''
  }
});

const onSearch = handleSubmit(async (values: { searchText: string }) => {
  if (values.searchText) {
    if (ratingList.value.length === 2) {
      showAlert({
        content: t('studio.prj_prod_mngmt.rating_build_review.direct_input.failed_reg_num_limit')
      });

      return;
    }

    const res = await searchRatingWithRatingClassificationApi(values.searchText);

    if (res) {
      if (
        ratingList.value.some((rating: RatingSearchResponse) => rating.ratingId === res.ratingId)
      ) {
        return;
      }
      ratingList.value.push(res);

      return;
    }

    showAlert({
      content: t(
        'studio.prj_prod_mngmt.rating_build_review.direct_input.inquiry_failed_reg_num_not_existed'
      )
    });
  }
});

const onRatingRemove = (ratingId: string) => {
  ratingList.value = ratingList.value.filter(
    (rating: RatingSearchResponse) => rating.ratingId !== ratingId
  );
};

const onSubmit = async () => {
  if (!(await checkProductPermission())) {
    return;
  }

  if (originalRatingList.value.length > 0 && isEqual(originalRatingList.value, ratingList.value)) {
    await showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.edit_no_change_msg')
    });

    redirectTo(PRODUCT_RATING_URL);
    return;
  }

  if (ratingList.value.length < 1) {
    await showAlert({
      content: t('studio.prj_prod_mngmt.rating_build_review.direct_input.required_val_popup')
    });
    return;
  }

  if (props.mode === RatingPageMode.DETAIL) {
    const confirm = await showConfirm({
      content: [
        t('studio.prj_prod_mngmt.rating_build_review.edit_cf_msg1'),
        '<br/>',
        t('studio.prj_prod_mngmt.rating_build_review.edit_cf_msg3')
      ],
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline',
      panelClasses: '!w-[56rem]',
      cancelClasses: '!w-[12.8rem]',
      confirmClasses: '!w-[12.8rem]'
    });

    if (!confirm) {
      return;
    }
  }

  if (props.mode === RatingPageMode.EDIT) {
    const confirm = await showConfirm({
      content: [
        t('studio.prj_prod_mngmt.rating_build_review.edit_cf_msg2'),
        '<br/>',
        t('studio.prj_prod_mngmt.rating_build_review.edit_cf_msg3')
      ],
      confirmLabel: t('studio.common.popup_case_cf_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline',
      panelClasses: '!w-[56rem]',
      cancelClasses: '!w-[12.8rem]',
      confirmClasses: '!w-[12.8rem]'
    });

    if (!confirm) {
      return;
    }
  }

  const data = ratingList.value.reduce(
    (acc: RegisterProductDirectRatingRequest, curr: RatingSearchResponse) => {
      acc.ratingIds.push(curr.ratingId);
      return acc;
    },
    {
      ratingIds: []
    }
  );
  await registerProductDirectRatingApi(productNo, data);
  await showAlert({
    content: t(
      props.mode === RatingPageMode.DETAIL
        ? 'studio.prj_prod_mngmt.rating_build_review.edit_done_msg'
        : 'studio.prj_prod_mngmt.rating_build_review.direct_input.reg_completed'
    )
  });
  redirectTo(PRODUCT_RATING_URL);
};

const translateList = (contentInfos: string[]) => {
  const list = contentInfos.map((contentInfo: string) => {
    return t(contentInfo);
  });

  return list.join(', ');
};

const initRatingsProduct = async () => {
  const ratingProduct = await fetchRatingsProductsApi(productNo);
  if (ratingProduct) {
    const ratingData = getCorrectRating(ratingProduct.requestRating, ratingProduct.releasedRatings);

    if (ratingData && Array.isArray(ratingData) && ratingData[0].directYn === Confirmation.YES) {
      const res = await Promise.all(
        ratingData.map((rating: ReleasedRatings) => {
          return searchRatingWithRatingClassificationApi(rating.ratingId);
        })
      );

      if (res && res[0]) {
        originalRatingList.value = res as RatingSearchResponse[];
        ratingList.value = JSON.parse(JSON.stringify(originalRatingList.value));
      }
    }
  }
};

const init = async () => {
  if (props.mode === RatingPageMode.DETAIL) {
    await initRatingsProduct();
  }
};

init();
</script>
